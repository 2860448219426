









import Vue from 'vue'
import NavBarComponent from '@/client/components/NavBarComponent.vue'

export default Vue.extend({
    name: 'Home',
    components: {
        NavBarComponent,
    },
})

