

















declare var kWidget: any

import Vue from 'vue'

import {Notifications, PlayerLifeCycle, PlayerState} from './kaltura'

export default Vue.extend({
    name: 'KalturaLivePlayer',
    // TODO check if we can read data from player?
    props: ['id'],
      data: () => ({
        kdp: null,
        isLive: false
      }),
    methods: {
      fullscreenOff() {
        console.log('Minimizing player')
        if(this.kdp) {
          // @ts-ignore
          this.kdp.sendNotification('closeFullScreen');
        }
      },

      checkLive() {
        let kdp = this.kdp as any
        let isLive = kdp.evaluate('{mediaProxy.isLive}')
        // let entry = kdp.evaluate('{mediaProxy.entry}')

        // console.log(entry)
        // if(entry.id !== this.$props.id) {
        //   console.log('media ID does not match')
        //   kdp.sendNotification("changeMedia", { "entryId" : kdp.entry_id });
        // }

        // auto play when going live
        if(isLive && this.isLive === false) {
          kdp.sendNotification(Notifications.doPlay)
        }

        // go offline
        if(!isLive && this.isLive === true) {
          this.$emit('stop')
        }

        this.isLive = isLive

        console.log(isLive ? 'Live' : 'Offline')
      },
        initPlayer() {

            if(this.$props.id) {
                // console.log(this.$props.id)
                // eslint-disable-next-line

                kWidget?.embed({
                'targetId': 'kaltura_player',
                'wid': '_2240091',
                'uiconf_id': 46492993,
                'entry_id': this.$props.id,
                'readyCallback': (playerId) => {
                  console.log('kWidget player ready: ' + playerId)
                  let kdp: any = document.getElementById( playerId )
                  this.kdp = kdp

                  setInterval(() => {this.checkLive()}, 1000)

                  Object.keys(Notifications).forEach(i => {

                    kdp.kBind(i, () => {
                      console.log(`%cNotification: ${i} - ${playerId}`, 'color: yellow')
                    })

                  })
                  Object.keys(PlayerLifeCycle).forEach(i => {

                    kdp.kBind(i, () => {
                      console.log(`%cLife Cycle: ${i} - ${playerId}`, 'color: red')
                    })

                  })
                  Object.keys(PlayerState).forEach(i => {

                    kdp.kBind(i, () => {
                      console.log(`State: ${i} - ${playerId}`)
                    })

                  })

                  kdp.kBind(PlayerLifeCycle.mediaLoaded, () => {
                    console.log('media loaded')

                    let isLive = kdp.evaluate('{mediaProxy.isLive}')
                    if(isLive) {
                      console.log(`%cEMIT: ${PlayerLifeCycle.mediaLoaded} - ${playerId}`, 'color: orange')

                      this.$emit('start')
                    }

                  })
                  kdp.kBind(Notifications.doStop, () => {
                    console.log(`%cEMIT: ${Notifications.doStop} - ${playerId}`, 'color: orange')

                    this.$emit('stop')
                  })

                },
              })

            }
        },
    },
    mounted() {
        this.initPlayer()
    }
})

