
















































































































































































import Vue from 'vue'
import KalturaLivePlayer from '@/components/KalturaLivePlayer.vue'
import KalturaVideoPlayer from '@/components/KalturaVideoPlayer.vue'
import NavBarComponent from '@/client/components/NavBarComponent.vue'
import Chat from '@/client/components/Chat.vue'
import {
  completeChallenge,
  getPrivateEventDetails,
  startWatching,
  stopWatching,
  submitAnswer,
  submitPollOption,
  attendeeLog
} from '@/client/firebase/api'
import dayjs from 'dayjs'
import {
  Attendee,
  ChallengePoll,
  ChallengePresence,
  ChallengeQuestion,
  ChallengeType,
  PXLEvent,
} from '@/client/types';
import { onNewChallenge } from '@/client/firebase/orm';
import {
  CHALLENGE_CLIENT_GRACE,
  CHALLENGE_CLIENT_TIMER,
  CHALLENGE_CLIENT_WINDOW
} from '@/config';

declare type LiveEventState = 'before' | 'during' | 'after'

    interface Data {
        loading: boolean

        live_event_state: LiveEventState
        is_recording_still_available: boolean

        token: string | null
        event: PXLEvent | null,
        attendee: Attendee | null,

        // TODO rename challenge to challenge_presence
        challenge_dialog: boolean,
        challenge_percentage: number,
        challenge_seconds: number,
        challenge_start_at: Date | null,
        challenge: ChallengePresence | null

        challenge_question_dialog: boolean
        challenge_question: ChallengeQuestion | null,
        challenge_question_option: string | null

        challenge_poll_dialog: boolean,
        challenge_poll: ChallengePoll | null,
        challenge_poll_option: string | null,
      
        challenge_poll_queue: ChallengePoll[] // TODO also use ChallengeQuestion
    }

    export default Vue.extend({
        name: 'PrivateEvent',
        components: {
            KalturaLivePlayer,
            KalturaVideoPlayer,
            Chat,
            NavBarComponent,
        },
        data: (): Data => ({
            loading: true,

            live_event_state: 'before',
            is_recording_still_available: true,

            token: null,
            event: null,
            attendee: null,

            challenge_question_dialog: false,
            challenge_question: null,
            challenge_question_option: null,

            challenge_dialog: false,
            challenge_percentage: 0,
            challenge_seconds: 0,
            challenge_start_at: null,
            challenge: null,

            challenge_poll_dialog: false,
            challenge_poll: null,
            challenge_poll_option: null,
            challenge_poll_queue: []
        }),
        computed: {
          isRecordingAvailable() {

            if(this.event) {

              if(this.event.recording_id === '') return false

              if(this.event.end_at && dayjs().isBefore(this.event.end_at as string)) {
                return false
              }

              return true
            }

            return false
          },
        },
        methods: {
            progressChallengeTimer() {

              if(this.challenge_start_at === null) {
                return
              }

              const secondsAfter = dayjs().diff(this.challenge_start_at, 'seconds')

              if(secondsAfter <= CHALLENGE_CLIENT_TIMER) {

                this.challenge_seconds = CHALLENGE_CLIENT_TIMER - secondsAfter
                this.challenge_percentage = (secondsAfter * 100) / CHALLENGE_CLIENT_TIMER
                setTimeout(this.progressChallengeTimer, 250)

              } else if(secondsAfter <= CHALLENGE_CLIENT_TIMER + CHALLENGE_CLIENT_GRACE){

                this.challenge_seconds = 0
                this.challenge_percentage = 100
                setTimeout(this.progressChallengeTimer, 250)

              } else {
                this.challenge_dialog = false
                this.challenge = null
                this.challenge_seconds = 0
                this.challenge_percentage = 0
                this.challenge_start_at = null

              }

            },
            fullscreenOff() {
              const player: any = this.$refs.player

              player.fullscreenOff()
            },
            startQuestionChallenge(challenge: ChallengeQuestion) {

              if(!challenge.question || !challenge.options || challenge.options.length === 0) {
                console.warn('Question is empty!')
                return
              }

              const minutesAfterCreated = dayjs().diff(challenge.created_at, 'minutes', true)

              // start challenge
              if(minutesAfterCreated < CHALLENGE_CLIENT_WINDOW) {
                attendeeLog(this.token as string, `Start vraag: ${challenge.id} - ${minutesAfterCreated} - ${dayjs().format()}`)

                this.challenge_question = challenge
                this.challenge_question_dialog = true

                this.fullscreenOff()
              } else {
                attendeeLog(this.token as string, `Start vraag - verlopen: ${challenge.id} - ${minutesAfterCreated} - ${dayjs().format()}`)
              }
            },
            playWarningSounds() {
              // ring alarming sound
              const audio = new Audio(require("@/assets/warning.mp3"))
              const audio2 = new Audio(require("@/assets/warning.mp3"))
              const audio3 = new Audio(require("@/assets/warning.mp3"))

              audio.volume = 0.5
              audio2.volume = 0.5
              audio3.volume = 0.5

              audio.play()
              setTimeout(() => {
                audio2.play()
              }, 200)
              setTimeout(() => {
                audio3.play()
              }, 400)
            },
              startPresenceChallenge(challenge: ChallengePresence) {
              const minutesAfterCreated = dayjs().diff(challenge.created_at, 'minutes', true)

              // start challenge
              if(minutesAfterCreated < CHALLENGE_CLIENT_WINDOW) {
                attendeeLog(this.token as string, `Start aanwezigheidscontrole: ${challenge.id} - ${minutesAfterCreated} - ${dayjs().format()}`)

                this.challenge = challenge
                this.challenge_seconds = CHALLENGE_CLIENT_TIMER
                this.challenge_percentage = 0
                this.challenge_start_at = new Date()
                this.challenge_dialog = true

                setTimeout(this.progressChallengeTimer, 500)

                this.fullscreenOff()

                this.playWarningSounds()
              } else {
                attendeeLog(this.token as string, `Start aanwezigheidscontrole - verlopen: ${challenge.id} - ${minutesAfterCreated} - ${dayjs().format()}`)
              }
            },
            startPollChallenge(challenge: ChallengePoll) {
              const minutesAfterCreated = dayjs().diff(challenge.created_at, 'minutes', true)

              // start challenge
              if(minutesAfterCreated < CHALLENGE_CLIENT_WINDOW) {
                attendeeLog(this.token as string, `Start poll: ${challenge.id} - ${minutesAfterCreated} - ${dayjs().format()}`)

                if(this.challenge_poll != null && !this.challenge_poll_dialog) {
                  this.challenge_poll_queue.push(challenge)

                } else {
                  this.challenge_poll = challenge
                  this.challenge_poll_dialog = true

                  this.fullscreenOff()
                }

              } else {
                attendeeLog(this.token as string, `Start poll - verlopen: ${challenge.id} - ${minutesAfterCreated} - ${dayjs().format()}`)
              }
            },
            initChallenges(eventId: string) {
              if (eventId) {
                console.log('Setup handler for challenges')

                onNewChallenge(eventId, challenge => {

                  if(challenge.type === ChallengeType.presence) {
                    this.startPresenceChallenge(challenge)
                  } else if(challenge.type === ChallengeType.question) {
                    this.startQuestionChallenge(challenge)
                  } else if(challenge.type === ChallengeType.poll) {
                    this.startPollChallenge(challenge)
                  }

                })

              } else {
                console.warn('No event id set!')
              }
            },
            async completeChallenge() {
              // make sure the dialog is always turned off
              this.challenge_dialog = false

              if(this.challenge === null) {
                console.log('No challenge active')
                return
              }

              // retry x 3
              try {
                await completeChallenge(this.token as string, this.challenge.id)
              } catch (e) {
                try {
                  await completeChallenge(this.token as string, this.challenge.id)
                } catch (e) {
                  try {
                    await completeChallenge(this.token as string, this.challenge.id)
                  } catch (e) {
                      console.error('completeChallenge failed after 3 retries')
                  }
                }
              }

              attendeeLog(this.token as string, `Bevestig aanwezigheidscontrole: ${this.challenge.id} - ${dayjs().format()}`)

              console.log('CHALLENGE COMPLETED')
            },

            calculateStateOfEvent() {

              console.info('Calc state')

              if(!this.event) return

              // recording
              if(this.event.available_until && dayjs().isAfter(this.event.available_until as string)) {
                this.is_recording_still_available = false
              }

              // live
              let new_state: LiveEventState = 'before'
              if(dayjs().isBefore(this.event.start_at)) {
                const minutesToLive = dayjs(this.event.start_at).diff(dayjs(), 'minute', true)
                new_state = minutesToLive > 15 ? 'before' : 'during'

              } else {
                new_state = 'during'

                if(this.event.end_at) {
                  if(dayjs().isAfter(dayjs(this.event.end_at))) {

                    const minutesAfterLive = dayjs().diff(dayjs(this.event.end_at), 'minute', true)

                    if(minutesAfterLive > 15) {
                      new_state = 'after'

                      // if viewer was already watching, he can stay watching
                      if(this.live_event_state === 'during' && minutesAfterLive < 60) {
                        new_state = 'during'
                      }

                    }

                  }
                }
              }



              this.live_event_state = new_state
            },

            async initEvent(token: string) {
                // reset
                this.loading = true

                this.token = null
                this.event = null
                this.attendee = null

                try {
                    const {event, attendee} = await getPrivateEventDetails(token)

                    this.event = event
                    this.attendee = attendee
                    this.token = token

                    attendeeLog(this.token as string, `Open event: ${dayjs().format()}`)

                    this.initChallenges(event.id)

                  // start updating sate
                  this.calculateStateOfEvent()
                  setInterval(() => {this.calculateStateOfEvent()}, 60 * 1000)

                } catch (e) {
                    console.log(e)
                }

                this.loading = false
            },

          async playerStart() {
              console.log('PLAYER START')

              await startWatching(this.token as string)
          },
          async playerStop() {
              console.log('PLAYER STOP')

              await stopWatching(this.token as string)
          },
          async submitQuestionOption() {
              if(this.challenge_question_option === null || this.challenge_question_option === '') {
                console.log('No selected option')
                return
              }

              await submitAnswer(this.token as string, this.challenge_question_option)

              this.challenge_question = null
              this.challenge_question_dialog = false
              this.challenge_question_option = null
          },
          async submitPollOption() {
              if(this.challenge_poll === null || this.challenge_poll_option === '') {
                console.log('No selected option')
                return
              }

            await submitPollOption(this.token as string, this.challenge_poll.id as string, this.challenge_poll_option as string)

            this.challenge_poll = null
            this.challenge_poll_dialog = false
            this.challenge_poll_option = null

            if(this.challenge_poll_queue.length > 0) {
              const nextChallenge = this.challenge_poll_queue.shift() as ChallengePoll

              this.challenge_poll = nextChallenge
              this.challenge_poll_dialog = true
              this.challenge_poll_option = null

            }
          }
        },
        beforeRouteEnter(to, from, next) {
            console.log('enter')
            console.log(to.params.token)

            next((vm: any) => {
                vm.initEvent(to.params.token)
            })
        },
        beforeRouteUpdate(to, from, next) {
            console.log('update')
            console.log(to.params.token)

            next((vm: any) => {
                vm.initEvent(to.params.token)
            })
        },
        created(): void {
          console.log('close handlers')

          window.addEventListener("beforeunload", () => {
            stopWatching(this.token as string)
          });

        },
      async destroyed() {
          stopWatching(this.token as string)
        }
    })

