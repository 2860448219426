








































































































import Vue from 'vue'
import KalturaLivePlayer from '@/components/KalturaLivePlayer.vue'
import KalturaVideoPlayer from '@/components/KalturaVideoPlayer.vue'
import NavBarComponent from '@/client/components/NavBarComponent.vue'
import ChatAnonymousComponent from '@/client/components/ChatAnonymousComponent.vue';
import {
  getPublicEventDetails,
  submitPollOptionPublic
} from '@/client/firebase/api'
import dayjs from 'dayjs'
import {
  ChallengePoll,
  ChallengeType,
  PXLEvent
} from '@/client/types';
import { onNewChallenge } from '@/client/firebase/orm';
import { CHALLENGE_CLIENT_WINDOW } from '@/config';

declare type LiveEventState = 'before' | 'during' | 'after'


interface Data {
    loading: boolean,

    live_event_state: LiveEventState
    is_recording_still_availble: boolean

    event: PXLEvent | null,

    challenge_poll_dialog: boolean,
    challenge_poll: ChallengePoll | null,
    challenge_poll_option: string | null,
    challenge_poll_queue: ChallengePoll[]
}

export default Vue.extend({
    name: 'PublicEvent',
    components: {
      ChatAnonymousComponent,
        KalturaLivePlayer,
        KalturaVideoPlayer,
        NavBarComponent,
    },
    data: (): Data => ({
        loading: true,

        live_event_state: 'before',
        is_recording_still_availble: true,

        event: null,

        challenge_poll_dialog: false,
        challenge_poll: null,
        challenge_poll_option: null,
        challenge_poll_queue: []
    }),
    computed: {
        hoursToLive() {

            if(this.event) {
                return dayjs(this.event.start_at).diff(dayjs(), 'hour', true)
            }

            return null
        },
        isRecordingAvailable() {

          if(this.event) {

            if(this.event.recording_id === '') return false

            if(this.event.end_at && dayjs().isBefore(this.event.end_at as string)) {
              return false
            }

            return true
          }

          return false
        },
    },
    methods: {
        calculateStateOfEvent() {

            console.info('Calc state')

            if(!this.event) return

            // recording
            if(this.event.available_until && dayjs().isAfter(this.event.available_until as string)) {
              this.is_recording_still_availble = false
            }

            // live
            let new_state: LiveEventState = 'before'
            if(dayjs().isBefore(this.event.start_at)) {
              const minutesToLive = dayjs(this.event.start_at).diff(dayjs(), 'minute', true)
              new_state = minutesToLive > 15 ? 'before' : 'during'

            } else {
              new_state = 'during'

              if(this.event.end_at) {
                if(dayjs().isAfter(dayjs(this.event.end_at))) {

                  const minutesAfterLive = dayjs().diff(dayjs(this.event.end_at), 'minute', true)

                  if(minutesAfterLive > 15) {
                    new_state = 'after'

                    // if viewer was already watching, he can stay watching
                    if(this.live_event_state === 'during' && minutesAfterLive < 60) {
                      new_state = 'during'
                    }
                  }

                }
              }
            }

            this.live_event_state = new_state
       },

        async initEvent(id: string) {
            // reset
            this.loading = true

            this.event = null

            try {
                this.event = await getPublicEventDetails(id)

                // start updating sate
                this.calculateStateOfEvent()
                setInterval(() => {this.calculateStateOfEvent()}, 60 * 1000)

                this.initChallenges(this.event.id)
            } catch(e) {
                console.log(e)
            }

            this.loading = false

        },

      fullscreenOff() {
        const player: any = this.$refs.player

        player.fullscreenOff()
      },

      initChallenges(eventId: string) {
        if (eventId) {
          console.log('Setup handler for challenges')

          onNewChallenge(eventId, challenge => {

            if(challenge.type === ChallengeType.poll) {
              this.startPollChallenge(challenge)
            }

          })

        } else {
          console.warn('No event id set!')
        }
      },


      startPollChallenge(challenge: ChallengePoll) {
        const minutesAfterCreated = dayjs().diff(challenge.created_at, 'minutes', true)

        // start challenge
        if(minutesAfterCreated < CHALLENGE_CLIENT_WINDOW) {

          if(this.challenge_poll != null && !this.challenge_poll_dialog) {
            this.challenge_poll_queue.push(challenge)

          } else {
            this.challenge_poll = challenge
            this.challenge_poll_dialog = true

            this.fullscreenOff()
          }

        }
      },
      async submitPollOption() {
        if(this.event === null || this.challenge_poll === null || this.challenge_poll_option === '') {
          console.log('No selected option')
          return
        }


        await submitPollOptionPublic(this.event.id as string, this.challenge_poll.id as string, this.challenge_poll_option as string)

        this.challenge_poll = null
        this.challenge_poll_dialog = false
        this.challenge_poll_option = null

        if(this.challenge_poll_queue.length > 0) {
          const nextChallenge = this.challenge_poll_queue.shift() as ChallengePoll

          this.challenge_poll = nextChallenge
          this.challenge_poll_dialog = true
          this.challenge_poll_option = null

        }
      }
    },
    beforeRouteEnter(to, from, next) {
        console.log('enter')
        console.log(to.params.id)

        next((vm: any) => {
            vm.initEvent(to.params.id)
        })
    },
    beforeRouteUpdate(to, from, next) {
        console.log('update')
        console.log(to.params.id)

        next((vm: any) => {
            vm.initEvent(to.params.id)
        })
    },
})

