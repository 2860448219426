import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
import {
    Challenge,
    Message,
} from '@/client/types';
import {
    COLLECTION_EVENTS,
    COLLECTION_EVENTS_CHALLENGES,
    COLLECTION_EVENTS_MESSAGES
} from '@/config';
import Unsubscribe = firebase.Unsubscribe;
import {
    eventChallengeFromFirebase,
    eventMessageFromFirebase,
} from '@/client/firebase/index';
import { getApp } from '@/client/firebase';


export async function onNewMessage(eventId: string, handleNewMessage: (message: Message) => void): Promise<Unsubscribe> {


    await getApp()

    return firebase
        .firestore()
        .collection(COLLECTION_EVENTS).doc(eventId)
        .collection(COLLECTION_EVENTS_MESSAGES)
        .orderBy('date', 'asc')
        .onSnapshot(snapshot => {

            // add new messages
            snapshot.docChanges().forEach((change) => {

                if (change.type === 'added') {

                    handleNewMessage(eventMessageFromFirebase(change.doc))

                }
            })

        })

}

export async function onNewChallenge(eventId: string, handleNewChallenge: (challenge: Challenge) => void): Promise<Unsubscribe> {

    return firebase
        .firestore()
        .collection(COLLECTION_EVENTS).doc(eventId)
        .collection(COLLECTION_EVENTS_CHALLENGES)
        .orderBy('created_at', 'asc')
        .onSnapshot(snapshot => {

            // add new messages
            snapshot.docChanges().forEach((change) => {

                if (change.type === 'added') {

                    handleNewChallenge(eventChallengeFromFirebase(change.doc))

                }
            })

        })

}
