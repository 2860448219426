

















declare var kWidget: any

import Vue from 'vue'

import {Notifications, PlayerLifeCycle, PlayerState} from './kaltura'


export default Vue.extend({
  name: 'KalturaLivePlayer',
  // TODO check if we can read data from player?
  props: ['id'],
  methods: {
    initPlayer() {

      if (this.$props.id) {
        // console.log(this.$props.id)
        // NOTE: http://player.kaltura.com/docs/api#doStop
        // eslint-disable-next-line
        kWidget?.embed({
          'targetId': 'kaltura_player',
          'wid': '_2240091',
          'uiconf_id': 46492993,
          'flashvars': {},
          'entry_id': this.$props.id,
          'readyCallback': (playerId) => {
            console.log('kWidget player ready: ' + playerId)
            let kdp: any = document.getElementById( playerId )

            Object.keys(Notifications).forEach(i => {

              kdp.kBind(i, () => {
                console.log(`%cNotification: ${i} - ${playerId}`, 'color: yellow')
              })

            })
            Object.keys(PlayerLifeCycle).forEach(i => {

              kdp.kBind(i, () => {
                console.log(`%cLife Cycle: ${i} - ${playerId}`, 'color: red')
              })

            })
            Object.keys(PlayerState).forEach(i => {

              kdp.kBind(i, () => {
                console.log(`State: ${i} - ${playerId}`)
              })

            })

            kdp.kBind(Notifications.doPlay, () => {
              console.log(`%cEMIT: ${Notifications.doPlay} - ${playerId}`, 'color: orange')

              this.$emit('start')
            })
            kdp.kBind(Notifications.doStop, () => {
              console.log(`%cEMIT: ${Notifications.doStop} - ${playerId}`, 'color: orange')

              this.$emit('stop')
            })


          },
        })

      }
    },
  },
  mounted() {
    this.initPlayer()
  },
})

