export enum Notifications {
  doPause = 'doPause',
  doPlay = 'doPlay',
  doStop = 'doStop',
  doSeek = 'doSeek',
  changeMedia = 'changeMedia',
  changeVolume = 'changeVolume',
  cleanMedia = 'cleanMedia',
  doSwitch = 'doSwitch',
  changePreferredBitrate = 'changePreferredBitrate',
  doReplay = 'doReplay',
  alert = 'alert',
  showUiElement = 'showUiElement',
  removeAlerts = 'removeAlerts',
  enableGui = 'enableGui',
  cancelAlerts = 'cancelAlerts',
  liveEntry = 'liveEntry',
  showClosedCaptions = 'showClosedCaptions',
  hideClosedCaptions = 'hideClosedCaptions',
}

export enum PlayerLifeCycle {
  // kdpReady = 'kdpReady',
  // kdpEmpty = 'kdpEmpty',
  // layoutReady = 'layoutReady',
  // layoutBuildDone = 'layoutBuildDone',
  playerReady = 'playerReady',

  readyToPlay = 'readyToPlay',
  readyToLoad = 'readyToLoad',
  // entryReady = 'entryReady',
  entryFailed = 'entryFailed',

  mediaReady = 'mediaReady',
  mediaError = 'mediaError',
  mediaLoaded = 'mediaLoaded',

  // LEGACY startUp = 'startUp',
  // LEGACY initiatApp = 'initiatApp',
  // LEGACY skinLoaded = 'skinLoaded',
  // LEGACY skinLoadFailed = 'skinLoadFailed',
  // LEGACY sourceReady = 'sourceReady',
  // LEGACY pluginsLoaded = 'pluginsLoaded',
  // LEGACY singlePluginLoaded = 'singlePluginLoaded',
  // LEGACY singlePluginFailedToLoad = 'singlePluginFailedToLoad',
  // LEGACY entryNotAvailable = 'entryNotAvailable',
}

export enum PlayerState {
  firstPlay = 'firstPlay',
  firstQuartile = 'firstQuartile',
  secondQuartile = 'secondQuartile',
  thirdQuartile = 'thirdQuartile',
  playerPlayEnd = 'playerPlayEnd',
  // durationChange = 'durationChange',
  // playerStateChange = 'playerStateChange',
  // playerPaused = 'playerPaused',
  // playerPlayed = 'playerPlayed',
  // userInitiatedPlay = 'userInitiatedPlay',
  // userInitiatedPause = 'userInitiatedPause',
  // preSeek = 'preSeek',
  // seek = 'seek',
  // seeked = 'seeked',
  // userInitiatedSeek = 'userInitiatedSeek',
  // monitorEvent = 'monitorEvent',
  // playerUpdatePlayhead = 'playerUpdatePlayhead',
  openFullScreen = 'openFullScreen',
  closeFullScreen = 'closeFullScreen',
  hasCloseFullScreen = 'hasCloseFullScreen',
  hasOpenedFullScreen = 'hasOpenedFullScreen',
  volumeChanged = 'volumeChanged',
  // mute = 'mute',
  // unmute = 'unmute',
  // bytesDownloadedChange = 'bytesDownloadedChange',
  // bytesTotalChange = 'bytesTotalChange',
  // bufferProgress = 'bufferProgress',
  // bufferChange = 'bufferChange',
  // bufferStartEvent = 'bufferStartEvent',
  // bufferEndEvent = 'bufferEndEvent',
  // freePreviewEnd = 'freePreviewEnd',
  // metadataReceived = 'metadataReceived',
  // cuePointsReceived = 'cuePointsReceived',
  // cuePointReached = 'cuePointReached',
  switchingChangeStarted = 'switchingChangeStarted',
  switchingChangeComplete = 'switchingChangeComplete',
  playbackComplete = 'playbackComplete',
  // closedCaptionsHidden = 'closedCaptionsHidden',
  // closedCaptionsDisplayed = 'closedCaptionsDisplayed',
  // changedClosedCaptions = 'changedClosedCaptions',

  // LEGACY volumeChangedEnd = 'volumeChangedEnd',
  // LEGACY rootResize = 'rootResize',
  // LEGACY mediaViewableChange = 'mediaViewableChange',
  // LEGACY changeMediaProcessStarted = 'changeMediaProcessStarted',
  // LEGACY  scrubberDragStart = 'scrubberDragStart',
  // LEGACY  scrubberDragEnd = 'scrubberDragEnd',
  // LEGACY  intelliSeek = 'intelliSeek',
}
