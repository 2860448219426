
















































































import Vue from 'vue'

import { postMessage } from '@/client/firebase/api'
import { MESSAGE_LENGTH } from '@/config'
import { Message } from '@/client/types';
import { onNewMessage } from '@/client/firebase/orm';


interface Data {
    valid: boolean
    saving: boolean
    message: string

    messages: Message[]

    unsubscribeHandler?: () => any

    MESSAGE_LENGTH: number
}

export default Vue.extend({
    name: 'chat',
    props: [
        'eventId',
        'attendeeId',
        'token',
    ],
    data: (): Data => ({
        valid: false,
        saving: false,
        message: '',

        messages: [],

        unsubscribeHandler: undefined,

        MESSAGE_LENGTH,
    }),
    methods: {
        initChat() {
            if (this.$props.eventId) {
                console.log('Setup handler for messages')

                onNewMessage(this.$props.eventId, message => {
                    // scroll status
                    const messagesElement: HTMLElement = this.$refs.messages as HTMLElement
                    const isScrollingToLatest = (messagesElement.scrollTop + messagesElement.clientHeight) >= messagesElement.scrollHeight

                    this.messages.push(message)

                    // scrolling
                    Vue.nextTick(() => {
                        if (isScrollingToLatest) {
                          messagesElement.scrollTop = messagesElement.scrollHeight - messagesElement.clientHeight
                        }
                    })
                })

            } else {
                console.warn('No event id set!')
            }
        },
        async submit() {
            console.log('Submitting')

            const form: any = this.$refs.form
            const isValid = form.validate()

            if (isValid) {
                this.saving = true

                console.log('Post message')

                try {
                    postMessage(this.token, this.message)
                    this.message = ''

                    form.resetValidation()
                    form.reset()
                } catch (e) {
                    console.error(e)
                }

                this.saving = false
            }

            return false
        },
    },
    mounted() {
        this.initChat()
    },
    destroyed(): void {
        try {
            if (this.unsubscribeHandler) {
                this.unsubscribeHandler()
            }
        } catch (e) {
            console.warn('Could not unsubscribe to messages')
        }

    },
})

