






























































































































import Vue from 'vue'

import { MESSAGE_LENGTH } from '@/config'
import {
  Message,
} from '@/client/types';
import {
  onNewMessage
} from '@/client/firebase/orm';
import { postPublicMessage } from '@/client/firebase/api';


interface Data {
    valid_message: boolean
    valid_name: boolean

    saving: boolean

    name: string
    name_input: string
    message: string

    messages: Message[]

    unsubscribeHandler?: () => any

    MESSAGE_LENGTH: number
}

export default Vue.extend({
    name: 'chatAnonymousComponent',
    props: [
        'event',
    ],
    data: (): Data => ({
        valid_message: false,
        valid_name: false,
        saving: false,

        name: '',
        name_input: '',
        message: '',

        messages: [],

        unsubscribeHandler: undefined,

        MESSAGE_LENGTH,
    }),
    methods: {
        async initChat() {
            if (this.$props.event.id) {
                console.log('Setup handler for messages')

                this.unsubscribeHandler = await onNewMessage(this.$props.event.id, (message) => {

                        // scroll status
                        const MARGIN = 5;
                        const messagesElement: HTMLElement = this.$refs.messages as HTMLElement
                        const isScrollingToLatest = (messagesElement.scrollTop + messagesElement.clientHeight + MARGIN) >= messagesElement.scrollHeight

                        // add new messages
                        this.messages.push(message)

                        // scrolling
                        Vue.nextTick(() => {
                            if(isScrollingToLatest) {
                                messagesElement.scrollTop = messagesElement.scrollHeight - messagesElement.clientHeight
                            }
                        })
                    })

            } else {
                console.warn('No event id set!')
            }
        },
        async submit() {
            console.log('Submitting')

            const form: any = this.$refs.form
            const isValid = form.validate()

            if (isValid) {
                this.saving = true

                console.log('Post message')

                try {
                    const message: Message = {
                      name: this.name,
                      message: `${this.message}`,
                      date: '', // TODO
                    }

                    this.message = ''
                    await postPublicMessage(this.$props.event, message)

                    form.resetValidation()
                    form.reset()
                } catch (e) {
                    console.error(e)
                }

                this.saving = false
            }

            return false
        },
    },
    mounted() {
        this.initChat()
    },
    destroyed(): void {
        try {
            if (this.unsubscribeHandler) {
                this.unsubscribeHandler()
            }
        } catch (e) {
            console.warn('Could not unsubscribe to messages')
        }

    },
})

