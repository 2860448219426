import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { Routes } from '../config'

import HomePage from '../views/HomePage.vue'
import PublicEventPage from '../views/PublicEventPage.vue'
import PrivateEventPage from '../views/PrivateEventPage.vue'

Vue.use(VueRouter)


const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: Routes.Home,
        component: HomePage,
    },
    {
        path: '/e/:token',
        name: Routes.PrivateEvent,
        component: PrivateEventPage,
    },
    {
        path: '/event/:id',
        name: Routes.PublicEvent,
        component: PublicEventPage,
    },
    {
      path: "*",
      component: HomePage
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
