import firebase from 'firebase'

// These imports load individual services into the firebase namespace.
import 'firebase/firestore'
import DocumentSnapshot = firebase.firestore.DocumentSnapshot
import Timestamp = firebase.firestore.Timestamp
import {
    Challenge,
    Message
} from '@/client/types';
import dayjs from 'dayjs';

function loadApp(): Promise<firebase.app.App> {

    const appPromise: Promise<firebase.app.App> = new Promise(function(resolve, reject) {

        try {

            fetch('/__/firebase/init.json').then(async response => {
                const json = await response.json()
                const app = firebase.initializeApp(json)

                if(process.env.NODE_ENV === 'development') {
                    console.log('app dev settings')
                    firebase.firestore().settings({
                        host: "localhost:5002",
                        ssl: false
                    })
                    // firebase.auth().useEmulator('http://localhost:9099/');
                    firebase.functions().useEmulator('localhost', 5001);
                }

                // console.log('app created')
                // console.log(json)
                // console.log(app)
                resolve(app)
            });

        } catch (e) {
            // console.log(e)
            reject()
        }

    })

    return appPromise
}

const appPromise: Promise<firebase.app.App> = loadApp()

export async function getApp(): Promise<firebase.app.App> {
    return appPromise
}

// const app = firebase.initializeApp({
//     apiKey: "AIzaSyAAdmgKqmSE5a-sZPaB_SKpOosJiKHknSE",
//     authDomain: "pxl-videoplatform.firebaseapp.com",
//     databaseURL: "https://pxl-videoplatform.firebaseio.com",
//     projectId: "pxl-videoplatform",
//     messagingSenderId: "378662921384",
//     appId: "1:378662921384:web:368d366109a915f0494e9d"
// })

export const firebaseApp = firebase


export function dateToTimestamp(date: Date): Timestamp {
    return Timestamp.fromDate(date)
}

export function timestampToDate(date: Timestamp): Date {
    return date.toDate()
}

export function timestampToDateString(date: Timestamp): string {
    return dayjs(date.toDate()).format()
}

export function eventMessageFromFirebase(messageDoc: DocumentSnapshot): Message {
    const data: any = messageDoc.data()

    return {
        id: messageDoc.id,
        attendeeId: data.attendeeId,
        message: data.message,
        name: data.name,
        date: timestampToDateString(data.date)
    }
}

export function eventChallengeFromFirebase(challengeDoc: DocumentSnapshot): Challenge {
    const data: any = challengeDoc.data()

    return {
        id: challengeDoc.id,
        type: data.type,
        created_at: timestampToDateString(data.created_at),
        options: data.options ?? undefined,
        question: data.question ?? undefined
    }
}
