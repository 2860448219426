import {
    Attendee,
    AttendeeLog,
    Message,
    PXLEvent
} from '@/client/types';
import firebase from 'firebase';

export async function getPublicEventDetails(id: string): Promise<PXLEvent> {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/event/public/${id}`)

    if (response.ok) {
        const responseAsJSON = await response.json()

        return responseAsJSON as PXLEvent
    }

    throw new Error('Fetching of event details failed')
}

export async function getPrivateEventDetails(token: string): Promise<{ event: PXLEvent, attendee: Attendee }> {
    const response = await fetch(`${process.env.VUE_APP_API_URL}/event/private/${token}`)

    if (response.ok) {
        const responseAsJSON = await response.json()

        return {
            event: responseAsJSON.event as PXLEvent,
            attendee: responseAsJSON.attendee as Attendee
        }
    }

    throw new Error('Fetching of event details failed')
}

export async function postMessage(token: string, message: string) {
    const options: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            message
        })
    }

    await fetch(`${process.env.VUE_APP_API_URL}/event/message/${token}`, options)
}

export async function postPublicMessage(event: PXLEvent, message: Message) {

    const options: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(message)
    }

    await fetch(`${process.env.VUE_APP_API_URL}/event/${event.id}/message/`, options)
}

export async function attendeeLog(token: string, message: string) {

    const attendeeLog: AttendeeLog = {
        message: message
    }

    const options: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(attendeeLog)
    }

    await fetch(`${process.env.VUE_APP_API_URL}/attendee/log/${token}`, options)
}

export async function startWatching(token: string) {
    const options: any = {
        method: 'POST',
    }

    await fetch(`${process.env.VUE_APP_API_URL}/attendee/start/${token}`, options)
}

export async function stopWatching(token: string) {
    const options: any = {
        method: 'POST',
    }

    await fetch(`${process.env.VUE_APP_API_URL}/attendee/stop/${token}`, options)
}

export async function submitAnswer(token: string, answer: string) {
    const options: any = {
        method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        answer
      })
    }

    await fetch(`${process.env.VUE_APP_API_URL}/attendee/answer/${token}`, options)
}

export async function submitPollOption(token: string, challenge_id: string, option: string) {
    const options: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            challenge_id,
            option
        })
    }

    await fetch(`${process.env.VUE_APP_API_URL}/challenge/poll/vote/${token}`, options)
}

export async function submitPollOptionPublic(event_id: string, challenge_id: string, option: string) {
    const options: any = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            event_id,
            challenge_id,
            option
        })
    }

    await fetch(`${process.env.VUE_APP_API_URL}/challenge/poll/publicvote`, options)
}

export async function completeChallenge(token: string, challenge_id: string) {
    const options: any = {
        method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          challenge_id
      })
    }

    await fetch(`${process.env.VUE_APP_API_URL}/challenge/presence/complete/${token}`, options)
}
