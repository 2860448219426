import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        }
        // themes: {
        //     light: {
        //         // primary: '#c7d330',
        //         primary: '#6f9118',
        //         secondary: '#6f9118',
        //         accent: '#82B1FF',
        //         error: '#FF5252',
        //         info: '#2196F3',
        //         success: '#4CAF50',
        //         warning: '#FFC107',
        //     },
        // },
    },
})
