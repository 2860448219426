import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

export declare type PXLEvent = {
    id: string
    name: string

    player_id: string
    recording_id: string

    start_at: string // date
    end_at?: string // date

    available_until?: string  // date

    has_chat: boolean
}

export declare type Attendee = {
    id: string
    name: string
    email: string

    answered: boolean
}

export declare type AttendeeLog = {
    id?: string
    created_at?: string // date
    message: string
}

export declare type Message = {
    id?: string,
    attendeeId?: string,
    name: string,
    message: string,
    date?: string | Timestamp // date
}

export enum ChallengeType {
    presence = 'presence',
    question = 'question',
    poll = 'poll'
}

export interface ChallengeBase {
    id: string,
    type: ChallengeType
    created_at: string // date
}

export interface ChallengePresence extends ChallengeBase {
    type: ChallengeType.presence
}

export interface ChallengeQuestion extends ChallengeBase {
    type: ChallengeType.question
    question: string,
    options: string[]
}

export interface ChallengePoll extends ChallengeBase {
    type: ChallengeType.poll
    question: string,
    options: string[]
}

export declare type Challenge = ChallengePresence | ChallengePoll | ChallengeQuestion


